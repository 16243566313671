var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "appsumo-register-funnel" }, [
    _c("div", { staticClass: "appsumo-register-funnel__main-block" }, [
      _c("div", { staticClass: "appsumo-register-funnel__main-block__inner" }, [
        _c(
          "div",
          { staticClass: "appsumo-register-funnel__main-block__heading" },
          [
            _vm._m(0),
            !_vm.hideAppSumo
              ? _c("div", { staticClass: "logo-separator with-separator" }, [
                  _vm._v("with"),
                ])
              : _vm._e(),
            !_vm.hideAppSumo
              ? _c(
                  "div",
                  { staticClass: "with-separator" },
                  [
                    _c("app-sumo-logo-dark", {
                      attrs: { width: 124, height: 19 },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          { staticClass: "appsumo-register-funnel__main-block__body" },
          [
            _c(
              "div",
              { staticClass: "appsumo-register-funnel__main-block__flow" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "appsumo-register-funnel__main-block__flow__heading",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "appsumo-register-funnel__main-block__flow__heading__title",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.hideAppSumo
                                ? _vm.$t("appsumo.welcome")
                                : _vm.$t("appsumo.welcomeSumoling")
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "appsumo-register-funnel__main-block__flow__content__activate-message",
                      },
                      [_vm._v(_vm._s(_vm.$t("appsumo.activateMessage")))]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "appsumo-register-funnel__main-block__flow__content",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "appsumo-register-funnel__main-block__flow__content__form",
                      },
                      [
                        _c("form", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "appsumo-register-funnel__main-block__flow__content__form__fields",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "appsumo-register-funnel__main-block__flow__content__form__fields__field",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "appsumo-register-funnel__main-block__flow__content__form__fields__field__inner",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.name,
                                            expression: "name",
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value:
                                              "required|validateStringForSpecialCharacters",
                                            expression:
                                              "'required|validateStringForSpecialCharacters'",
                                          },
                                        ],
                                        ref: "name",
                                        staticClass:
                                          "appsumo-register-funnel__main-block__flow__content__form__fields__field__input",
                                        class: {
                                          "appsumo-register-funnel__main-block__flow__content__form__fields__field__input--disabled":
                                            _vm.isAgent,
                                        },
                                        attrs: {
                                          type: "text",
                                          readonly: _vm.isAgent,
                                          "data-vv-validate-on": "blur",
                                          name: _vm.$t("vue.name"),
                                          "label-placeholder":
                                            _vm.$t("vue.name"),
                                        },
                                        domProps: { value: _vm.name },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.name = $event.target.value
                                          },
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "appsumo-register-funnel__main-block__flow__content__form__fields__field__label",
                                          class: {
                                            "appsumo-register-funnel__main-block__flow__content__form__fields__field__label--moved":
                                              _vm.name && _vm.name.length > 0,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$refs.name.focus()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm.isAgent
                                                  ? ""
                                                  : _vm.$t(
                                                      "inputs.enterYourName"
                                                    )
                                              ) +
                                              "\n                      "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm.errors.first(_vm.$t("vue.name"))
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-danger text-lg flex items-center",
                                          staticStyle: { "margin-top": "10px" },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              src: require("@/assets/images/pages/login/error-icon.svg"),
                                            },
                                          }),
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors.first(
                                                _vm.$t("vue.name")
                                              )
                                            ) + "\n                    "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "appsumo-register-funnel__main-block__flow__content__form__fields__field",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "appsumo-register-funnel__main-block__flow__content__form__fields__field__inner",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.email,
                                            expression: "email",
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required|email",
                                            expression: "'required|email'",
                                          },
                                        ],
                                        ref: "email",
                                        staticClass:
                                          "appsumo-register-funnel__main-block__flow__content__form__fields__field__input",
                                        attrs: {
                                          type: "email",
                                          readonly: "",
                                          "data-vv-validate-on": "blur",
                                          name: _vm.$t("inputs.email"),
                                        },
                                        domProps: { value: _vm.email },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.email = $event.target.value
                                          },
                                        },
                                      }),
                                      _c("div", {
                                        staticClass:
                                          "appsumo-register-funnel__main-block__flow__content__form__fields__field__label",
                                        class: {
                                          "appsumo-register-funnel__main-block__flow__content__form__fields__field__label--moved":
                                            _vm.email && _vm.email.length > 0,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$refs.email.focus()
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm.errors.first(_vm.$t("inputs.email"))
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-danger text-lg flex items-center",
                                          staticStyle: { "margin-top": "10px" },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              src: require("@/assets/images/pages/login/error-icon.svg"),
                                            },
                                          }),
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors.first(
                                                _vm.$t("inputs.email")
                                              )
                                            ) + "\n                    "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "appsumo-register-funnel__main-block__flow__content__form__fields__field",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "appsumo-register-funnel__main-block__flow__content__form__fields__field__inner",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.password,
                                            expression: "password",
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate.continues",
                                            value:
                                              "required|min:6|max:128|atLeastOneNumber|atLeastOneUppercase|atLeastOneSpecialCharacter",
                                            expression:
                                              "'required|min:6|max:128|atLeastOneNumber|atLeastOneUppercase|atLeastOneSpecialCharacter'",
                                            modifiers: { continues: true },
                                          },
                                        ],
                                        ref: "password",
                                        staticClass:
                                          "appsumo-register-funnel__main-block__flow__content__form__fields__field__input",
                                        attrs: {
                                          type: "password",
                                          autocomplete: "on",
                                          name: _vm.$t("inputs.password"),
                                          "label-placeholder":
                                            _vm.$t("inputs.password"),
                                        },
                                        domProps: { value: _vm.password },
                                        on: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.activateAccount.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.password = $event.target.value
                                          },
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "appsumo-register-funnel__main-block__flow__content__form__fields__field__label",
                                          class: {
                                            "appsumo-register-funnel__main-block__flow__content__form__fields__field__label--moved":
                                              _vm.password &&
                                              _vm.password.length > 0,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$refs.password.focus()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm.$t("inputs.password")
                                              ) +
                                              "\n                      "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm.errors &&
                                  _vm.errors.items &&
                                  _vm.errors.items.find(function (item) {
                                    return (
                                      item.field ===
                                        _vm.$t("inputs.password") &&
                                      ["required", "max"].includes(item.rule)
                                    )
                                  })
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-danger text-lg flex items-center",
                                          staticStyle: { "margin-top": "10px" },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              src: require("@/assets/images/pages/login/error-icon.svg"),
                                            },
                                          }),
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors.first(
                                                _vm.$t("inputs.password")
                                              )
                                            ) + "\n                    "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "appsumo-register-funnel__main-block__flow__content__form__fields__field__rules-wrapper",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "appsumo-register-funnel__main-block__flow__content__form__fields__field__rule",
                                          class: {
                                            "appsumo-register-funnel__main-block__flow__content__form__fields__field__rule--valid":
                                              _vm.password.length >= 6,
                                            "appsumo-register-funnel__main-block__flow__content__form__fields__field__rule--invalid":
                                              _vm.errors &&
                                              _vm.errors.items &&
                                              _vm.errors.items.find(function (
                                                item
                                              ) {
                                                return (
                                                  item.field ===
                                                    _vm.$t("inputs.password") &&
                                                  ["min"].includes(item.rule)
                                                )
                                              }),
                                          },
                                        },
                                        [
                                          _vm.password.length >= 6
                                            ? _c("check-icon", {
                                                staticClass:
                                                  "appsumo-register-funnel__main-block__flow__content__form__fields__field__rule__icon",
                                                attrs: {
                                                  color: "rgb(65, 184, 131)",
                                                  width: 16,
                                                  height: 16,
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(
                                            "\n\n                        " +
                                              _vm._s(
                                                _vm.$t(
                                                  "inputs.minimumCharacters"
                                                )
                                              ) +
                                              "\n                      "
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "appsumo-register-funnel__main-block__flow__content__form__fields__field__rule",
                                          class: {
                                            "appsumo-register-funnel__main-block__flow__content__form__fields__field__rule--valid":
                                              _vm.password.length &&
                                              !(
                                                _vm.errors &&
                                                _vm.errors.items &&
                                                _vm.errors.items.find(function (
                                                  item
                                                ) {
                                                  return (
                                                    item.field ===
                                                      _vm.$t(
                                                        "inputs.password"
                                                      ) &&
                                                    [
                                                      "atLeastOneUppercase",
                                                    ].includes(item.rule)
                                                  )
                                                })
                                              ),
                                            "appsumo-register-funnel__main-block__flow__content__form__fields__field__rule--invalid":
                                              _vm.errors &&
                                              _vm.errors.items &&
                                              _vm.errors.items.find(function (
                                                item
                                              ) {
                                                return (
                                                  item.field ===
                                                    _vm.$t("inputs.password") &&
                                                  [
                                                    "atLeastOneUppercase",
                                                  ].includes(item.rule)
                                                )
                                              }),
                                          },
                                        },
                                        [
                                          _vm.password.length &&
                                          !(
                                            _vm.errors &&
                                            _vm.errors.items &&
                                            _vm.errors.items.find(function (
                                              item
                                            ) {
                                              return (
                                                item.field ===
                                                  _vm.$t("inputs.password") &&
                                                [
                                                  "atLeastOneUppercase",
                                                ].includes(item.rule)
                                              )
                                            })
                                          )
                                            ? _c("check-icon", {
                                                staticClass:
                                                  "appsumo-register-funnel__main-block__flow__content__form__fields__field__rule__icon",
                                                attrs: {
                                                  color: "rgb(65, 184, 131)",
                                                  width: 16,
                                                  height: 16,
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(
                                            "\n\n                        " +
                                              _vm._s(
                                                _vm.$t(
                                                  "inputs.atLeastUppercase"
                                                )
                                              ) +
                                              "\n                      "
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "appsumo-register-funnel__main-block__flow__content__form__fields__field__rule",
                                          class: {
                                            "appsumo-register-funnel__main-block__flow__content__form__fields__field__rule--valid":
                                              _vm.password.length &&
                                              !(
                                                _vm.errors &&
                                                _vm.errors.items &&
                                                _vm.errors.items.find(function (
                                                  item
                                                ) {
                                                  return (
                                                    item.field ===
                                                      _vm.$t(
                                                        "inputs.password"
                                                      ) &&
                                                    [
                                                      "atLeastOneNumber",
                                                    ].includes(item.rule)
                                                  )
                                                })
                                              ),
                                            "appsumo-register-funnel__main-block__flow__content__form__fields__field__rule--invalid":
                                              _vm.errors &&
                                              _vm.errors.items &&
                                              _vm.errors.items.find(function (
                                                item
                                              ) {
                                                return (
                                                  item.field ===
                                                    _vm.$t("inputs.password") &&
                                                  ["atLeastOneNumber"].includes(
                                                    item.rule
                                                  )
                                                )
                                              }),
                                          },
                                        },
                                        [
                                          _vm.password.length &&
                                          !(
                                            _vm.errors &&
                                            _vm.errors.items &&
                                            _vm.errors.items.find(function (
                                              item
                                            ) {
                                              return (
                                                item.field ===
                                                  _vm.$t("inputs.password") &&
                                                ["atLeastOneNumber"].includes(
                                                  item.rule
                                                )
                                              )
                                            })
                                          )
                                            ? _c("check-icon", {
                                                staticClass:
                                                  "appsumo-register-funnel__main-block__flow__content__form__fields__field__rule__icon",
                                                attrs: {
                                                  color: "rgb(65, 184, 131)",
                                                  width: 16,
                                                  height: 16,
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(
                                            "\n\n                        " +
                                              _vm._s(
                                                _vm.$t("inputs.atLeastNumber")
                                              ) +
                                              "\n                      "
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "appsumo-register-funnel__main-block__flow__content__form__fields__field__rule",
                                          class: {
                                            "appsumo-register-funnel__main-block__flow__content__form__fields__field__rule--valid":
                                              _vm.password.length &&
                                              !(
                                                _vm.errors &&
                                                _vm.errors.items &&
                                                _vm.errors.items.find(function (
                                                  item
                                                ) {
                                                  return (
                                                    item.field ===
                                                      _vm.$t(
                                                        "inputs.password"
                                                      ) &&
                                                    [
                                                      "atLeastOneSpecialCharacter",
                                                    ].includes(item.rule)
                                                  )
                                                })
                                              ),
                                            "appsumo-register-funnel__main-block__flow__content__form__fields__field__rule--invalid":
                                              _vm.errors &&
                                              _vm.errors.items &&
                                              _vm.errors.items.find(function (
                                                item
                                              ) {
                                                return (
                                                  item.field ===
                                                    _vm.$t("inputs.password") &&
                                                  [
                                                    "atLeastOneSpecialCharacter",
                                                  ].includes(item.rule)
                                                )
                                              }),
                                          },
                                        },
                                        [
                                          _vm.password.length &&
                                          !(
                                            _vm.errors &&
                                            _vm.errors.items &&
                                            _vm.errors.items.find(function (
                                              item
                                            ) {
                                              return (
                                                item.field ===
                                                  _vm.$t("inputs.password") &&
                                                [
                                                  "atLeastOneSpecialCharacter",
                                                ].includes(item.rule)
                                              )
                                            })
                                          )
                                            ? _c("check-icon", {
                                                staticClass:
                                                  "appsumo-register-funnel__main-block__flow__content__form__fields__field__rule__icon",
                                                attrs: {
                                                  color: "rgb(65, 184, 131)",
                                                  width: 16,
                                                  height: 16,
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(
                                            "\n\n                        " +
                                              _vm._s(
                                                _vm.$t(
                                                  "inputs.atLeastSpecialCharacter"
                                                )
                                              ) +
                                              "\n                      "
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm.accountCreationSuccessful
                  ? [
                      _c(
                        "div",
                        {
                          staticClass:
                            "appsumo-register-funnel--all-good__loading",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "appsumo-register-funnel--all-good__loading__loader",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/register-funnel/check.png"),
                                  alt: "Success",
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "appsumo-register-funnel--all-good__loading__loader__title",
                                },
                                [_vm._v(_vm._s(_vm.$t("vue.allGood")))]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "appsumo-register-funnel--all-good__loading__loader__text",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("vue.redirectToAccount"))
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _c(
              "div",
              {
                staticClass:
                  "appsumo-register-funnel__main-block__flow__content__next-button-wrapper",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "appsumo-register-funnel__main-block__flow__content__next-button",
                    class: {
                      "appsumo-register-funnel__main-block__flow__content__next-button--disabled":
                        _vm.isButtonDisabled,
                    },
                    on: { click: _vm.activateAccount },
                  },
                  [
                    [_vm._v(_vm._s(_vm.$t("vue.activateAccount")))],
                    _c("img", {
                      staticClass:
                        "appsumo-register-funnel__main-block__flow__content__next-button__icon",
                      attrs: {
                        src: require("@/assets/images/register-funnel/forward-arrow.png"),
                        alt: "Next",
                      },
                    }),
                  ],
                  2
                ),
              ]
            ),
          ]
        ),
      ]),
    ]),
    _c("div", { staticClass: "appsumo-register-funnel__info-block" }, [
      _c("div", { staticClass: "appsumo-register-funnel__info-block__inner" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/images/register-funnel/people-1.png"),
            alt: "Hi there!",
          },
        }),
        _c(
          "div",
          { staticClass: "appsumo-register-funnel__info-block__title" },
          [_vm._v(_vm._s(_vm.$t("vue.registerFunnelInfoBlockTitle")))]
        ),
        _c(
          "div",
          { staticClass: "appsumo-register-funnel__info-block__description" },
          [_vm._v(_vm._s(_vm.$t("vue.registerFunnelInfoBlockDescription")))]
        ),
      ]),
    ]),
    _vm.isLoadingRegisterFunnel
      ? _c("div", { staticClass: "appsumo-register-funnel__loading" }, [
          _c(
            "div",
            { staticClass: "appsumo-register-funnel__loading__loader" },
            [
              _c("span", {
                staticClass:
                  "appsumo-register-funnel__loading__loader__spinner",
              }),
              _c(
                "div",
                {
                  staticClass: "appsumo-register-funnel__loading__loader__text",
                },
                [_vm._v(_vm._s(_vm.loaderSpinnerText))]
              ),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo-separator" }, [
      _c(
        "a",
        {
          staticClass:
            "appsumo-register-funnel__main-block__heading__logo-wrapper",
          attrs: { href: "https://letsconnect.at/" },
        },
        [
          _c("img", {
            staticClass:
              "appsumo-register-funnel__main-block__heading__logo-img",
            attrs: {
              src: require("@/assets/images/register-funnel/vega_logo.svg"),
              alt: "Logo",
            },
          }),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }