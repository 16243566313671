<template>
  <div class="appsumo-register-funnel">
    <div class="appsumo-register-funnel__main-block">
      <div class="appsumo-register-funnel__main-block__inner">
        <div class="appsumo-register-funnel__main-block__heading">
          <div class="logo-separator">
            <a href="https://letsconnect.at/" class="appsumo-register-funnel__main-block__heading__logo-wrapper">
              <img src="@/assets/images/register-funnel/vega_logo.svg" alt="Logo" class="appsumo-register-funnel__main-block__heading__logo-img" />
            </a>
          </div>
          <div v-if="!hideAppSumo" class="logo-separator with-separator">with</div>
          <div v-if="!hideAppSumo" class="with-separator">
            <app-sumo-logo-dark :width="124" :height="19" />
          </div>
        </div>

        <div class="appsumo-register-funnel__main-block__body">
          <div class="appsumo-register-funnel__main-block__flow">
            <div class="appsumo-register-funnel__main-block__flow__heading">
              <div class="appsumo-register-funnel__main-block__flow__heading__title">
                {{ hideAppSumo ? $t('appsumo.welcome') : $t('appsumo.welcomeSumoling') }}
              </div>
              <div class="appsumo-register-funnel__main-block__flow__content__activate-message">{{ $t('appsumo.activateMessage') }}</div>
            </div>

            <div class="appsumo-register-funnel__main-block__flow__content">
              <div class="appsumo-register-funnel__main-block__flow__content__form">
                <form>
                  <div class="appsumo-register-funnel__main-block__flow__content__form__fields">
                    <div class="appsumo-register-funnel__main-block__flow__content__form__fields__field">
                      <div class="appsumo-register-funnel__main-block__flow__content__form__fields__field__inner">
                        <input
                          ref="name"
                          type="text"
                          class="appsumo-register-funnel__main-block__flow__content__form__fields__field__input"
                          :class="{
                            'appsumo-register-funnel__main-block__flow__content__form__fields__field__input--disabled': isAgent
                          }"
                          v-model="name"
                          :readonly="isAgent"
                          v-validate="'required|validateStringForSpecialCharacters'"
                          data-vv-validate-on="blur"
                          :name="$t('vue.name')"
                          :label-placeholder="$t('vue.name')"
                        />

                        <div
                          class="appsumo-register-funnel__main-block__flow__content__form__fields__field__label"
                          :class="{
                            'appsumo-register-funnel__main-block__flow__content__form__fields__field__label--moved': name && name.length > 0
                          }"
                          @click="$refs.name.focus()"
                        >
                          {{ isAgent ? '' : $t('inputs.enterYourName') }}
                        </div>
                      </div>

                      <span v-if="errors.first($t('vue.name'))" class="text-danger text-lg flex items-center" style="margin-top: 10px">
                        <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first($t('vue.name')) }}
                      </span>
                    </div>

                    <div class="appsumo-register-funnel__main-block__flow__content__form__fields__field">
                      <div class="appsumo-register-funnel__main-block__flow__content__form__fields__field__inner">
                        <input
                          ref="email"
                          type="email"
                          class="appsumo-register-funnel__main-block__flow__content__form__fields__field__input"
                          v-model="email"
                          readonly
                          v-validate="'required|email'"
                          data-vv-validate-on="blur"
                          :name="$t('inputs.email')"
                        />

                        <div
                          class="appsumo-register-funnel__main-block__flow__content__form__fields__field__label"
                          :class="{
                            'appsumo-register-funnel__main-block__flow__content__form__fields__field__label--moved': email && email.length > 0
                          }"
                          @click="$refs.email.focus()"
                        ></div>
                      </div>

                      <span v-if="errors.first($t('inputs.email'))" class="text-danger text-lg flex items-center" style="margin-top: 10px">
                        <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first($t('inputs.email')) }}
                      </span>
                    </div>

                    <div class="appsumo-register-funnel__main-block__flow__content__form__fields__field">
                      <div class="appsumo-register-funnel__main-block__flow__content__form__fields__field__inner">
                        <input
                          ref="password"
                          type="password"
                          v-model="password"
                          autocomplete="on"
                          class="appsumo-register-funnel__main-block__flow__content__form__fields__field__input"
                          v-validate.continues="'required|min:6|max:128|atLeastOneNumber|atLeastOneUppercase|atLeastOneSpecialCharacter'"
                          :name="$t('inputs.password')"
                          :label-placeholder="$t('inputs.password')"
                          @keyup.enter="activateAccount"
                        />

                        <div
                          class="appsumo-register-funnel__main-block__flow__content__form__fields__field__label"
                          :class="{
                            'appsumo-register-funnel__main-block__flow__content__form__fields__field__label--moved': password && password.length > 0
                          }"
                          @click="$refs.password.focus()"
                        >
                          {{ $t('inputs.password') }}
                        </div>
                      </div>

                      <span
                        v-if="
                          errors && errors.items && errors.items.find((item) => item.field === $t('inputs.password') && ['required', 'max'].includes(item.rule))
                        "
                        class="text-danger text-lg flex items-center"
                        style="margin-top: 10px"
                      >
                        <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first($t('inputs.password')) }}
                      </span>

                      <div class="appsumo-register-funnel__main-block__flow__content__form__fields__field__rules-wrapper">
                        <div
                          class="appsumo-register-funnel__main-block__flow__content__form__fields__field__rule"
                          :class="{
                            'appsumo-register-funnel__main-block__flow__content__form__fields__field__rule--valid': password.length >= 6,
                            'appsumo-register-funnel__main-block__flow__content__form__fields__field__rule--invalid':
                              errors && errors.items && errors.items.find((item) => item.field === $t('inputs.password') && ['min'].includes(item.rule))
                          }"
                        >
                          <check-icon
                            v-if="password.length >= 6"
                            color="rgb(65, 184, 131)"
                            class="appsumo-register-funnel__main-block__flow__content__form__fields__field__rule__icon"
                            :width="16"
                            :height="16"
                          />

                          {{ $t('inputs.minimumCharacters') }}
                        </div>

                        <div
                          class="appsumo-register-funnel__main-block__flow__content__form__fields__field__rule"
                          :class="{
                            'appsumo-register-funnel__main-block__flow__content__form__fields__field__rule--valid':
                              password.length &&
                              !(
                                errors &&
                                errors.items &&
                                errors.items.find((item) => item.field === $t('inputs.password') && ['atLeastOneUppercase'].includes(item.rule))
                              ),
                            'appsumo-register-funnel__main-block__flow__content__form__fields__field__rule--invalid':
                              errors &&
                              errors.items &&
                              errors.items.find((item) => item.field === $t('inputs.password') && ['atLeastOneUppercase'].includes(item.rule))
                          }"
                        >
                          <check-icon
                            v-if="
                              password.length &&
                              !(
                                errors &&
                                errors.items &&
                                errors.items.find((item) => item.field === $t('inputs.password') && ['atLeastOneUppercase'].includes(item.rule))
                              )
                            "
                            color="rgb(65, 184, 131)"
                            class="appsumo-register-funnel__main-block__flow__content__form__fields__field__rule__icon"
                            :width="16"
                            :height="16"
                          />

                          {{ $t('inputs.atLeastUppercase') }}
                        </div>

                        <div
                          class="appsumo-register-funnel__main-block__flow__content__form__fields__field__rule"
                          :class="{
                            'appsumo-register-funnel__main-block__flow__content__form__fields__field__rule--valid':
                              password.length &&
                              !(
                                errors &&
                                errors.items &&
                                errors.items.find((item) => item.field === $t('inputs.password') && ['atLeastOneNumber'].includes(item.rule))
                              ),
                            'appsumo-register-funnel__main-block__flow__content__form__fields__field__rule--invalid':
                              errors &&
                              errors.items &&
                              errors.items.find((item) => item.field === $t('inputs.password') && ['atLeastOneNumber'].includes(item.rule))
                          }"
                        >
                          <check-icon
                            v-if="
                              password.length &&
                              !(
                                errors &&
                                errors.items &&
                                errors.items.find((item) => item.field === $t('inputs.password') && ['atLeastOneNumber'].includes(item.rule))
                              )
                            "
                            color="rgb(65, 184, 131)"
                            class="appsumo-register-funnel__main-block__flow__content__form__fields__field__rule__icon"
                            :width="16"
                            :height="16"
                          />

                          {{ $t('inputs.atLeastNumber') }}
                        </div>

                        <div
                          class="appsumo-register-funnel__main-block__flow__content__form__fields__field__rule"
                          :class="{
                            'appsumo-register-funnel__main-block__flow__content__form__fields__field__rule--valid':
                              password.length &&
                              !(
                                errors &&
                                errors.items &&
                                errors.items.find((item) => item.field === $t('inputs.password') && ['atLeastOneSpecialCharacter'].includes(item.rule))
                              ),
                            'appsumo-register-funnel__main-block__flow__content__form__fields__field__rule--invalid':
                              errors &&
                              errors.items &&
                              errors.items.find((item) => item.field === $t('inputs.password') && ['atLeastOneSpecialCharacter'].includes(item.rule))
                          }"
                        >
                          <check-icon
                            v-if="
                              password.length &&
                              !(
                                errors &&
                                errors.items &&
                                errors.items.find((item) => item.field === $t('inputs.password') && ['atLeastOneSpecialCharacter'].includes(item.rule))
                              )
                            "
                            color="rgb(65, 184, 131)"
                            class="appsumo-register-funnel__main-block__flow__content__form__fields__field__rule__icon"
                            :width="16"
                            :height="16"
                          />

                          {{ $t('inputs.atLeastSpecialCharacter') }}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <template v-if="accountCreationSuccessful">
              <div class="appsumo-register-funnel--all-good__loading">
                <div class="appsumo-register-funnel--all-good__loading__loader">
                  <img src="@/assets/images/register-funnel/check.png" alt="Success" />
                  <div class="appsumo-register-funnel--all-good__loading__loader__title">{{ $t('vue.allGood') }}</div>
                  <div class="appsumo-register-funnel--all-good__loading__loader__text">{{ $t('vue.redirectToAccount') }}</div>
                </div>
              </div>
            </template>
          </div>

          <div class="appsumo-register-funnel__main-block__flow__content__next-button-wrapper">
            <div
              class="appsumo-register-funnel__main-block__flow__content__next-button"
              :class="{ 'appsumo-register-funnel__main-block__flow__content__next-button--disabled': isButtonDisabled }"
              @click="activateAccount"
            >
              <template>{{ $t('vue.activateAccount') }}</template>

              <img
                src="@/assets/images/register-funnel/forward-arrow.png"
                alt="Next"
                class="appsumo-register-funnel__main-block__flow__content__next-button__icon"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="appsumo-register-funnel__info-block">
      <div class="appsumo-register-funnel__info-block__inner">
        <img src="@/assets/images/register-funnel/people-1.png" alt="Hi there!" />
        <div class="appsumo-register-funnel__info-block__title">{{ $t('vue.registerFunnelInfoBlockTitle') }}</div>
        <div class="appsumo-register-funnel__info-block__description">{{ $t('vue.registerFunnelInfoBlockDescription') }}</div>
      </div>
    </div>

    <div v-if="isLoadingRegisterFunnel" class="appsumo-register-funnel__loading">
      <div class="appsumo-register-funnel__loading__loader">
        <span class="appsumo-register-funnel__loading__loader__spinner" />
        <div class="appsumo-register-funnel__loading__loader__text">{{ loaderSpinnerText }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

// VeeValidate
import { Validator } from 'vee-validate'
// Merge all defined languages in the dictionary and sets the current locale.
import validationDictionary from '@/validations/validationDictionary'

import CheckIcon from '@/components/icons/CheckIcon'

import AppSumoLogoDark from '@/components/icons/appsumo/AppSumoLogoDark'

import { commonFunctions } from '@/mixins/commonFunctions'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default {
  components: {
    CheckIcon,
    AppSumoLogoDark
  },

  mixins: [commonFunctions],

  data: () => ({
    isAgent: false,
    isAdmin: false,

    accountCreationSuccessful: false,
    loaderSpinnerText: 'Processing...',
    appsumoAccountInfo: null,

    name: '',
    email: '',
    password: '',

    currentStep: 1
  }),

  computed: {
    ...mapGetters({
      isLoadingRegisterFunnel: 'isLoadingRegisterFunnel'
    }),
    isButtonDisabled() {
      return this.errors.any() || this.name === '' || this.password === '' || this.email === ''
    },
    hideAppSumo() {
      return this.$route.name === 'activate-user'
    }
  },

  async created() {
    /* Localize validator */
    this.loadSpinner(true, this.$i18n.t('appsumo.loading'))
    Validator.localize(this.$i18n.locale, validationDictionary[this.$i18n.locale])
    this.parseQueryParameters()
  },

  async mounted() {
    this.loaderSpinnerText = this.$i18n.t('vue.processing')
    if (
      this.$route.query.lang &&
      this.$i18n.locale &&
      this.$route.query.lang !== this.$i18n.locale &&
      this.$languagesSuppported.includes(this.$route.query.lang)
    ) {
      this.$i18n.locale = this.$route.query.lang
    }
  },

  methods: {
    ...mapMutations({
      updateIsLoadingRegisterFunnel: 'UPDATE_IS_LOADING_REGISTER_FUNNEL'
    }),

    loadSpinner(enable, text) {
      if (text) {
        this.loaderSpinnerText = text
      }
      this.updateIsLoadingRegisterFunnel(enable)
    },

    async parseQueryParameters() {
      if (!(this.$route && this.$route.query)) {
        return this.$router.push({ name: 'page-login', params: { from: 'appsumo-activate' } })
      }

      const query = this.$route.query
      this.isAgent = Boolean(query.agent_id && (query.appsumo_uuid || query.account_uuid) && query.source)
      this.isAdmin = Boolean(query.id && query.source)

      if (!this.isAgent && !this.isAdmin) {
        return this.$router.push({ name: 'page-login', params: { from: 'appsumo-activate' } })
      }

      if (this.$route.query.id) {
        const appsumoUUID = this.$route.query.id
        const appsumoRef = await this.$db.collection('appsumo-accounts').doc(appsumoUUID).get()
        this.appsumoAccountInfo = appsumoRef.data()

        if (
          !this.appsumoAccountInfo ||
          !this.appsumoAccountInfo.activation_email ||
          !this.appsumoAccountInfo.companyId ||
          (this.appsumoAccountInfo && this.appsumoAccountInfo.hasSetPassword)
        ) {
          return this.$router.push({ name: 'page-login', params: { from: 'appsumo-activate' } })
        }
        this.email = this.appsumoAccountInfo.activation_email
      }

      if (this.$route.query.agent_id && (this.$route.query.appsumo_uuid || this.$route.query.account_uuid)) {
        const appsumoUUID = this.$route.query.appsumo_uuid || this.$route.query.account_uuid
        const appsumoRef = await this.$db.collection('appsumo-accounts').doc(appsumoUUID).get()
        const appsumoAccountInfo = appsumoRef.data()

        const userRef = await this.$db.collection('users').doc(this.$route.query.agent_id).get()
        const userData = userRef.data()

        if (!userData || !userData.email || appsumoAccountInfo.companyId !== userData.company) {
          return this.$router.push({ name: 'page-login', params: { from: 'appsumo-activate' } })
        }
        this.email = userData.email
        this.name = userData.displayName
      }

      this.loadSpinner(false)
    },

    async activateAccount() {
      if (this.isButtonDisabled) {
        return
      }
      const result = await this.$validator.validateAll()
      if (!result) {
        return
      }
      this.loadSpinner(true, this.$i18n.t('appsumo.processing'))
      try {
        let appsumoAccountInfo = null
        if (this.$route.query.id) {
          appsumoAccountInfo = {
            name: this.name,
            email: this.email,
            uuid: this.appsumoAccountInfo.uuid,
            password: this.password,
            language: this.$i18n.locale
          }
        }

        if (this.$route.query.agent_id && (this.$route.query.appsumo_uuid || this.$route.query.account_uuid)) {
          appsumoAccountInfo = {
            name: this.name,
            email: this.email,
            agent_id: this.$route.query.agent_id,
            appsumo_uuid: this.$route.query.appsumo_uuid || this.$route.query.account_uuid,
            password: this.password,
            language: this.$i18n.locale
          }
        }

        if (!appsumoAccountInfo) {
          return this.$router.push({ name: 'page-login', params: { from: 'appsumo-activate' } })
        }

        const activateAppSumoAccount = this.$functions.httpsCallable('appsumoActivateAccount')
        const activateAccountResult = await activateAppSumoAccount(appsumoAccountInfo)
        if (activateAccountResult && activateAccountResult.data) {
          await this.$firebase.auth().signInWithCustomToken(activateAccountResult.data)
          await this.$firebase.auth().currentUser.getIdTokenResult(true)
          this.loadSpinner(false)
          this.goToYourAccount()
        }
      } catch (error) {
        /* eslint-disable no-console */
        console.log(error.message)
      }
    },

    async goToYourAccount() {
      this.accountCreationSuccessful = true

      setTimeout(() => {
        window.location.href = '/account-created/success'
      }, 3000)
    }
  }
}
</script>

<style scoped lang="scss">
@import './register-funnel.scss';
</style>
