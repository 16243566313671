<template>
  <div class="register-page">
    <register-funnel />
  </div>
</template>

<script>
import { muser } from '@/mixins/muser'
import RegisterFunnel from './RegisterFunnel.vue'

export default {
  components: {
    RegisterFunnel
  },

  mixins: [muser],

  async beforeMount() {
    await this.LOGOUT_USER()
  }
}
</script>
