<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    :stroke="color"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-check"
  >
    <polyline points="20 6 9 17 4 12"></polyline>
  </svg>
</template>

<script>
export default {
  name: 'CheckIcon',
  props: {
    color: {
      type: String,
      default: '#fff'
    },
    width: {
      type: Number,
      default: 35
    },
    height: {
      type: Number,
      default: 35
    },
    showCircle: {
      type: Boolean,
      required: false,
      default: true
    }
  }
}
</script>
